<script lang="ts" setup>
defineProps<{
  image: string;
  simple?: boolean | undefined;
}>();
const colorMode = useColorMode();
</script>

<template>
  <div class="relative">
    <NuxtImg
      :src="`/images/${image}-${colorMode.preference}.png`"
      alt="Description of image"
      class="w-full blur-[1.2px]" />
    <UCard
      class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
      <h1 class="font-bold text-center sm:text-xl">
        Upgrade to Premium subscription
      </h1>
      <ClientOnly>
        <Vue3Lottie
          class="hidden sm:flex"
          animationLink="/lottie-upgrade-to-premium.json"
          :height="150"
          :width="200" />
      </ClientOnly>
      <p v-if="!simple" class="py-4">
        Unlock all premium features with a Premium Subscription. Get full access
        to reports and charts, all integrations, unlimited alerts, and much
        more. Upgrade now to unlock the full potential of our platform and make
        the most out of your experience.
      </p>
      <template #footer>
        <div class="flex justify-end">
          <UButton
            :to="{
              path: '/system/user/account/billing',
            }"
            label="Upgrade to Premium"
            color="primary" />
        </div>
      </template>
    </UCard>
  </div>
</template>

<style></style>
